<template>
    <div class="axil-signin-area">
        <!-- Start Header -->
        <div class="signin-header">
            <div class="row align-items-center">
                <div class="col-sm-4">
                    <router-link to="/" class="site-logo"
                        ><img
                            src="/logo.png"
                            alt="logo"
                            style="max-height: 100px"
                    /></router-link>
                </div>
                <div class="col-sm-8">
                    <div class="singin-header-btn">
                        <p>{{ $t("Nincs még fiókod?") }}</p>
                        <router-link
                            to="/registration"
                            class="axil-btn btn-bg-secondary sign-up-btn"
                            >{{ $t("Regisztrálj most") }}</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- End Header -->

        <div class="row">
            <div class="col-xl-4 col-lg-6">
                <div
                    class="axil-signin-banner bg_image bg_image--9"
                    style="background: url('/ragavan.png'); background-repeat: no-repeat; background-size: cover;"
                >
                    <h3 class="title" style="color: white">
                        {{
                            $t(
                                "Magic Singles - Magyarország legnagyobb Online Mtg Single Webshopja"
                            )
                        }}
                    </h3>
                </div>
            </div>
            <div class="col-lg-6 offset-xl-2">
                <div class="axil-signin-form-wrap">
                    <div class="axil-signin-form">
                        <h3 class="title">{{ $t("Bejelentkezés") }}</h3>
                        <p class="b2 mb--55">
                            {{ $t("Add meg az adataidat") }}
                        </p>
                        <form class="singin-form" v-on:submit.prevent="doLogin">
                            <div class="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    v-model="model.email"
                                    placeholder="annie@example.com"
                                />
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    v-model="model.password"
                                    placeholder="********"
                                />
                            </div>
                            <div
                                class="form-group d-flex align-items-center justify-content-between"
                            >
                                <button
                                    type="submit"
                                    class="axil-btn btn-bg-primary submit-btn"
                                >
                                    {{ $t("Belépés") }}
                                </button>
                                <router-link
                                    to="/forget-password"
                                    class="forgot-btn"
                                    >{{ $t("Elfelejtett jelszó") }}</router-link
                                >
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Login",
    data() {
        return {
            model: {
                email: null,
                password: null
            }
        };
    },
    methods: {
        doLogin() {
            const self = this;
            ApiService.post(`/login`, this.model).then(response => {
                self.$gtag.event("login", {
                    event_category: "engagement",
                    event_label: "login",
                    value: self.model.email
                });

                this.$cookies.set("id_token", response.data.token, 86400);
                this.$cookies.set("user", response.data, 86400);

                this.$router.push({ name: "Index" });
            });
        }
    }
};
</script>

<style scoped></style>
